/** @jsxImportSource @emotion/react */
import {
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
} from '@material-ui/core/styles/index.js';
import { ThemeProvider } from '@material-ui/styles';
import {
  createTheme as createThemeMui,
  ThemeProvider as ThemeProviderMui,
} from '@mui/material';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { FONTS } from '../../common/constants.ts';

const BLUE = {
  light: COLORS.BLUE,
  dark: COLORS.BLUE,
  main: COLORS.BLUE,
  contrastText: '#FFFFFF',
};

const defaultThemeOptions: ThemeOptions = {
  palette: {
    primary: BLUE,
    secondary: BLUE,
    text: {
      disabled: '#888888',
    },
    common: {
      white: '#FFFFFF',
      black: color.format(-0.6),
    },
  },
  typography: {
    fontFamily: FONTS.Roboto.family,
    allVariants: {
      color: color.format(-0.8),
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        '&:hover': {
          borderBottom: `solid 2px ${COLORS.BLUE}`,
        },
      },
      input: {
        cursor: 'text',
      },
    },
    MuiFormLabel: {
      root: {
        color: color.format(-0.45),
      },
    },
  },
};

export const theme = responsiveFontSizes(createTheme(defaultThemeOptions));

const themeMui = createThemeMui({
  palette: {
    primary: BLUE,
    secondary: BLUE,
    text: {
      disabled: '#888888',
    },
    common: {
      white: '#FFFFFF',
      black: color.format(-0.6),
    },
  },
  typography: {
    fontFamily: FONTS.Roboto.family,
    allVariants: {
      color: color.format(-0.8),
    },
  },
});

export const Theme = ({ children }: { children: React.ReactNode }) => (
  <ThemeProvider theme={theme}>
    <ThemeProviderMui theme={themeMui}>{children}</ThemeProviderMui>
  </ThemeProvider>
);
